import React from "react";
import Box from "../components/Box";
import TextF8 from "../components/TextF8";
import TextF4 from "../components/TextF4";
import Romanize from "../components/Romanize";
import StyledLink from "../components/StyledLink";
import Header from "../components/Header";
import Stats from "../components/Stats";
import PropTypes from "prop-types";

import { themeGet } from "styled-system";
import styled from "styled-components";
import {
  MediumContainer,
  Container,
  Row,
  Col
} from "../components/Grid";
import { connect } from "react-redux";
import * as _ from "lodash";
import { navigate } from "@reach/router";
import * as routes from "../constants/routes";
import { ESSAY } from "../constants/quiztypes";
import { graphql } from "gatsby";

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: 100vh;
`;

const Card = styled(Box)`
  height: 100%;
  hover {
    cursor: pointer;
  }
`;

const CardSubHeading = styled(TextF8)`
  margin-top: 0;
  margin-bottom: 0;
  span {
    opacity: 0.5;
  }
`;

const CardHeading = styled(TextF4)`
  margin: 0;
`;

const SectionLink = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  color: #29264c;
  padding: 0 2rem;
  margin-top: -1px;
  :hover {
    background: #e9e9ed;
  }
`;

const SectionLinkInner = styled(Box)`
  border-bottom: 1px solid #e9e9ed;
  padding: 1rem 0 0.7rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
`;

const Exercise = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  color: #29264c;
  padding: 0 2rem;
  margin-top: -1px;
  :hover {
    background: #e9e9ed;
  }
`;

const ExerciseInner = styled(Box)`
  padding: 1rem 1rem 0.7rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
`;

const ExerciseMeta = styled(TextF8)`
  color: #9492a5;
  margin: 0;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  width: 70px;
  text-align: right;
`;

const StyledF4 = styled(TextF4)`
  text-align: center;
  color: white;
  margin: 0;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const breadcrumbs = [{ title: "Course progress", path: "/progress" }];

class Progress extends React.Component {
  quizzesForSection(part, section) {
    const tag = `ai_${part}_${section}`;

    let quizzesSorted = _.get(this.props.tags, tag, []).map(
      quizid => this.props.quizzes[quizid]
    );

    quizzesSorted.sort((a, b) => {
      let titleA = a.data.title;
      let titleB = b.data.title;

      return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
    });

    if (!quizzesSorted || (!!quizzesSorted && !quizzesSorted[0])) {
      return [];
    }

    return quizzesSorted;
  }

  componentDidMount() {}

  awaitingPeerReview(id) {
    const { stats } = this.props;

    return (
      !!stats &&
      !!stats.essaysAwaitingPeerReviewsGiven &&
      ~stats.essaysAwaitingPeerReviewsGiven.indexOf(id)
    );
  }

  exerciseStatus(id) {
    const { stats, quizzes, answers } = this.props;
    const quiz = _.get(quizzes, id, null);
    const answer = _.get(answers, id, {});

    const { answered, rejected } = stats;

    if (
      !quiz ||
      (!answered && !rejected) ||
      (!!answered &&
        !_.includes(answered, id) &&
        !!rejected &&
        !~Object.keys(rejected).indexOf(id))
    ) {
      return null;
    }
    if (answer.confirmed) {
      return (
        Math.round(_.get(answer, `validation.normalizedPoints`, 0) * 100) + "%"
      );
    }

    if (stats.rejected && _.includes(Object.keys(stats.rejected), id)) {
      return "rejected";
    }

    if (quiz.data.type === ESSAY) {
      return this.awaitingPeerReview(id)
        ? "awaiting your peer reviews"
        : "awaiting confirmation";
    }

    return "";
  }

  render() {
    const { data, stats, tags, quizzes, answers } = this.props;
    const loggedIn = this.context.loggedIn;

    if (!loggedIn) {
      navigate(routes.SIGN_IN);
      return null;
    }

    let totalCount = "--";
    let totalCompleted = "--";
    let percentage = "--";
    let points = "--";
    let maxPoints = "--";
    let maxCompletedPoints = "--";
    let normalizedPoints = "--";
    let maxNormalizedPoints = "--";
    let maxCompletedNormalizedPoints = "--";
    let percentagePoints = "--";
    let awaitingPeerReview = "--";
    let rejected = "--";

    if (!!stats) {
      totalCount = stats.maxNormalizedPoints;
      totalCompleted = stats.confirmedAmount;
      percentage = stats.progress;
      points = stats.points;
      maxPoints = stats.maxPoints;
      maxCompletedPoints = stats.maxCompletedPoints;
      normalizedPoints = stats.normalizedPoints;
      maxNormalizedPoints = stats.maxNormalizedPoints;
      maxCompletedNormalizedPoints = stats.maxCompletedNormalizedPoints;
      percentagePoints =
        stats.maxCompletedPoints > 0
          ? Math.round((stats.points / stats.maxCompletedPoints) * 100)
          : 0;
      awaitingPeerReview = stats.essaysAwaitingPeerReviewsGiven
        ? stats.essaysAwaitingPeerReviewsGiven.length
        : 0;
      rejected = stats.rejected ? Object.keys(stats.rejected).length : 0;
    }

    /*     if (!data) {
      return <div>Loading...</div>
    } */

    return (
      <BackgroundBox>
        <Header breadcrumbs={breadcrumbs} />

        {!loggedIn ? (
          <MediumContainer py={[4]}>
            <Box my={4}>
              <Box display="flex" justifyContent="center">
                <Box w={"240px"}>
                  <div>Please log in</div>
                </Box>
              </Box>
            </Box>
          </MediumContainer>
        ) : (
          <div>
            <MediumContainer py={[4]}>
              <Box my={4}>
                <Box display="flex" justifyContent="center">
                  <Stats />
                </Box>
              </Box>
              <Box my={4}>
                <StyledF4>
                  You have scored <b>{percentagePoints}</b>% of the possible
                  points so far.
                </StyledF4>
              </Box>
              {awaitingPeerReview > 0 ? (
                <Box my={4}>
                  <StyledF4>
                    There {awaitingPeerReview === 1 ? "is" : "are"}{" "}
                    <b>{awaitingPeerReview}</b> exercise
                    {awaitingPeerReview === 1 ? "" : "s"} awaiting your peer
                    reviews
                  </StyledF4>
                </Box>
              ) : null}
              {rejected > 0 ? (
                <Box my={4}>
                  <StyledF4>
                    You have <b style={{ color: "#FF0000" }}>{rejected}</b>{" "}
                    rejected answer{rejected === 1 ? "" : "s"}
                  </StyledF4>
                </Box>
              ) : null}
            </MediumContainer>

            <Container p={[3, 4, 4]}>
              <Row>
                {data &&
                  data.allParts &&
                  data.allParts.edges &&
                  data.allParts.edges.map(({ node: { frontmatter } }) => (
                    <Col
                      key={frontmatter.part}
                      w={[12 / 12, 6 / 12, 4 / 12]}
                      mb={3}
                    >
                      <Card>
                        <Box px={4} pt={4} bg="white">
                          <CardSubHeading>
                            Chapter {frontmatter.part}
                          </CardSubHeading>
                          <CardHeading>{frontmatter.title}</CardHeading>
                        </Box>
                        <Box pb={4} bg="#fff">
                          <div>
                            {data.allSections.edges
                              .filter(
                                ({
                                  node: {
                                    frontmatter: { part }
                                  }
                                }) => part === frontmatter.part
                              )
                              .map(({ node: { frontmatter } }) => (
                                <div>
                                  <SectionLink
                                    key={frontmatter.path}
                                    to={frontmatter.path}
                                  >
                                    <SectionLinkInner>
                                      <div>
                                        <Romanize num={frontmatter.section} />{" "}
                                        {frontmatter.title}
                                      </div>
                                    </SectionLinkInner>
                                  </SectionLink>
                                  {tags && !quizzes.initializing
                                    ? (
                                        this.quizzesForSection(
                                          frontmatter.part,
                                          frontmatter.section
                                        ) || []
                                      ).map(quiz => (
                                        <Exercise
                                          key={quiz.data._id}
                                          to={frontmatter.path}
                                        >
                                          <ExerciseInner>
                                            <div>{quiz.data.title}</div>
                                            <div>
                                              <ExerciseMeta>
                                                {this.exerciseStatus(
                                                  quiz.data._id
                                                )}
                                              </ExerciseMeta>
                                            </div>
                                          </ExerciseInner>
                                        </Exercise>
                                      ))
                                    : null}
                                </div>
                              ))}
                          </div>
                        </Box>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Container>
          </div>
        )}
      </BackgroundBox>
    );
  }

  static contextTypes = {
    loggedIn: PropTypes.bool
  };
}

const mapStateToProps = state => {
  return {
    quizzes: state.quizzes,
    answers: state.answers,
    peerReviews: state.peerReviews,
    stats: state.stats,
    tags: state.tags
  };
};

export default connect(mapStateToProps, null)(Progress);

export const pageQuery = graphql`
  query statsContent {
    allParts: allMarkdownRemark(
      sort: { fields: [frontmatter___part], order: ASC }
      filter: { frontmatter: { type: { eq: "part" } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
            bannerImage {
              publicURL
            }
          }
        }
      }
    }
    allSections: allMarkdownRemark(
      sort: { fields: [frontmatter___section], order: ASC }
      filter: { frontmatter: { type: { eq: "section" } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
          }
        }
      }
    }
    site: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
